.footer {
  display: block;
  margin: 20px auto;
  padding: 20px 0 0;
}
 
.footer::before,
.footer::after {
  display: block;
  width: 60px;
  height: 1px;
  border-top: 1px solid gray;
}
